<template>
    <div id="sub-group">
        <table class="table table-hover ui-table tw-table">
            <thead>
                <tr>
                    <th class="tw-sticky tw-top-0 tw-z-10" style="background:white !important">
                        <input
                            v-model="searchPermissionText"
                            type="text"
                            style="width:300px"
                            class="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                            :placeholder="$t('search')"
                        />
                    </th>
                    <th
                        v-for="column in permissionColumns"
                        :key="column"
                        class="tw-sticky tw-top-0 tw-z-10 tw-w-20 tw-text-center"
                        style="background:white !important"
                    >
                        <div
                            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full"
                        >
                            <p class="tw-mb-0 tw-text-black">
                                {{ $t(column.toLowerCase()) }}
                            </p>
                            <ts-checkbox
                                :checkedValue="allCheckedBy(column)"
                                @change="
                                    checked => selectAllBy(column, checked)
                                "
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(group, groupName) in permissionGroups">
                    <tr :key="group.id">
                        <td>
                            <span
                                class="tw-text-sm tw-font-semibold tw-pt-6 tw-inline-block"
                                >{{ groupName }}</span
                            >
                        </td>
                        <td v-for="n in permissionColumns.length" :key="n">
                            <div class="tw-flex tw-justify-center tw-pt-6">
                                <ts-checkbox
                                    :checkedValue="
                                        allCheckedByGroup(
                                            groupName,
                                            permissionColumns[n - 1]
                                        )
                                    "
                                    @change="
                                        checked =>
                                            selectByGroup(
                                                groupName,
                                                permissionColumns[n - 1],
                                                checked
                                            )
                                    "
                                />
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="(permissions, resourceName) in group"
                        :key="permissions.id"
                    >
                        <td>
                            <span class="tw-ml-4 tw-mr-6">
                                {{ resourceName }}
                            </span>
                        </td>
                        <td v-for="n in permissionColumns.length" :key="n">
                            <div class="tw-flex tw-justify-center">
                                <ts-checkbox
                                    v-model="model.permissions"
                                    :value="isShow(permissions, n).id"
                                    v-if="isShow(permissions, n) !== undefined"
                                />
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
    union,
    uniq,
    difference,
    intersection,
    flatMap,
    map,
    filter
} from "lodash";
export default {
    name: "permissionComponent",
    props: ["value"],
    data() {
        return {
            loading: false,
            visible: false,
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("auth/user", ["permissions"]),
        ...mapGetters("auth/user", ["permissionGroups", "permissionColumns"]),
        searchPermissionText: {
            get() {
                return this.$store.state.auth.user.searchPermissionText;
            },
            set(newValue) {
                this.$store.commit("auth/user/SET_SEARCH_PERMISSION", newValue);
            }
        },
        idPermissionsBy: vm => column => {
            return uniq(
                map(
                    filter(vm.permissions, p => {
                        return p.name
                            .toLowerCase()
                            .startsWith(column.toLowerCase());
                    }),
                    "id"
                )
            );
        },
        allCheckedBy: vm => column => {
            const idPermissions = vm.idPermissionsBy(column);
            return (
                intersection(vm.model.permissions, idPermissions).length ===
                idPermissions.length
            );
        },
        idPermissionsGroup: vm => (groupName, column) => {
            return uniq(
                map(
                    filter(flatMap(vm.permissionGroups[groupName]), p => {
                        return p.name
                            .toLowerCase()
                            .startsWith(column.toLowerCase());
                    }),
                    "id"
                )
            );
        },
        allCheckedByGroup: vm => (groupName, column) => {
            const idPermissions = vm.idPermissionsGroup(groupName, column);
            return (
                intersection(vm.model.permissions, idPermissions).length ===
                idPermissions.length
            );
        },
        isShow: () => (permission, pOrder) => {
            switch (pOrder) {
                case 1:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("view") >= 0
                    )[0];
                case 2:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("create") >= 0
                    )[0];

                case 3:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("update") >= 0
                    )[0];

                case 4:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("delete") >= 0
                    )[0];

                default:
                    return undefined;
            }
        }
    },
    methods: {
        selectAllBy(column, checked) {
            if (checked) {
                this.model.permissions = union(
                    this.model.permissions,
                    this.idPermissionsBy(column)
                );
            } else {
                this.model.permissions = difference(
                    this.model.permissions,
                    this.idPermissionsBy(column)
                );
            }
        },
        selectByGroup(groupName, column, checked) {
            if (checked) {
                this.model.permissions = union(
                    this.model.permissions,
                    this.idPermissionsGroup(groupName, column)
                );
            } else {
                this.model.permissions = difference(
                    this.model.permissions,
                    this.idPermissionsGroup(groupName, column)
                );
            }
        }
    }
};
</script>

<style lang="css" scoped>
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-color: #e2e7eb;
    padding: 4px 2px !important;
}
#sub-group {
    min-height: 30vh;
    max-height: 70vh;
    overflow-y: scroll;
}
</style>
