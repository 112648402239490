<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-3">
            <h1>{{ $t("user.userType") }}</h1>
            <a-radio-group
							v-model="model.user_type"
							@change="getFormView" 
							default-value="admin" button-style="outline" class="tw-mb-3">
                <template
									v-if="$whois.admin()"
								>
									<a-radio-button value="admin">
                    {{ $t('user.officeStaff') }}
									</a-radio-button>
								</template>
								<a-radio-button value="agency">
										{{ $t('user.agency') }}
								</a-radio-button>
                <a-radio-button value="shop">
                    {{ $t('user.shop') }}
                </a-radio-button>
                <a-radio-button value="driver">
                    {{ $t('user.driver') }}
                </a-radio-button>
            </a-radio-group>
						<a-select
							v-if="model.user_type == 'admin'"
							v-model="model.employee_id" 
							class="tw-w-full"
							:placeholder="$t('user.employee')"
							:options="employee"
							showSearch
							:filterOption="filterSearch"
						></a-select>
						<a-select
							v-if="model.user_type == 'agency'"
							v-model="model.agency_id"
							:placeholder="$t('user.agency')"
							class="tw-w-full"
							:options="agency"
							showSearch
							:filterOption="filterSearch" 
						></a-select>
						<a-select
							v-if="model.user_type == 'shop'"
							v-model="model.shop_id" 
							:options="shop" 
							class="tw-w-full"
							:placeholder="$t('user.shop')"
							showSearch
							:filterOption="filterSearch" 
						></a-select>
						<a-select
							v-if="model.user_type == 'driver'"
							v-model="model.driver_id"
							:options="driver"   
							class="tw-w-full"
							:placeholder="$t('user.driver')"
							showSearch
							:filterOption="filterSearch" 
						></a-select>
						<div class="tw-text-red-600" v-if="errors.has('employee_id')">
							{{ errors.first('employee_id') }}
						</div>
						<div class="tw-text-red-600" v-if="errors.has('agency_id')">
							{{ errors.first('agency_id') }}
						</div>
						<div class="tw-text-red-600" v-if="errors.has('shop_id')">
							{{ errors.first('shop_id') }}
						</div>
						<div class="tw-text-red-600" v-if="errors.has('driver_id')">
							{{ errors.first('driver_id') }}
						</div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Errors } from 'form-backend-validation'
export default {
    name: "userType",
		props: ["value", "validate"],
		data() {
			return {
				errors: new Errors(),
				model: {}
			}
		},
		created() {
      this.model = this.value;
    },
		computed: {
			...mapState('auth/user', ['formModels']),
			employee(){
				return []
			},
			agency() {
				if(this.formModels.agency == undefined)  return []
				return this.formModels.agency.map(el => ({
					value: el.agency_id,
					label: el.agency_name
				}))
			},
			shop() {
				if(this.formModels.shop == undefined)  return []
				return this.formModels.shop.map(el => ({
					value: el.shop_id,
					label: el.shop_name
				}))
			},
			driver() {
				if(this.formModels.driver == undefined)  return []
				return this.formModels.driver.map(el => ({
					value: el.driver_id,
					label: el.driver_name
				}))
			}
		},
		methods: {
			...mapActions('auth/user', ['getFormViewData', 'getUserRole', 'getPermissions']),
			async getFormView(){
				this.$emit('loading', true)
				await this.getUserRole({
					user_id: this.model.user_type
				});
				await this.getPermissions({
					user_id: this.model.user_type
				});
				this.getFormViewData({
					index: this.model.user_type,
					params: {
						fnName: this.model.user_type
					}
				})
				.finally(() => {
					this.$emit('loading', false)
				})
			},
			filterSearch(input, option) {
				return	option.componentOptions.children[0].text
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
			}
		},
		watch: {
			validate: function(error) {
				this.errors = new Errors(error.errors)
			}
		}
};
</script>
