<template>
    <ts-panel>
        <ts-panel-wrapper>
            <h1 class="tw-mb-0">{{ $t("user.role") }}</h1>
						<div class="tw-mt-3 tw-capitalize">
							<a-radio-group v-model="model.roles" :options="roles" />
						</div>
						<div
							v-if="errors.has('roles')"
							class="tw-text-red-600 tw-mt-3"
						>
							{{ errors.first('roles') }}
						</div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'

export default {
	name: 'userRole',
	props: ['value', 'validate'],
	data() {
		return {
			model: {},
			errors: new Errors()
		}
	},
	computed: {
		roles() {
			return this.$store.state.auth.user.userRole.map(el => ({
				label: el.name,
				value: el.id
			}))
		}
	},
	created () {
		this.model = this.value;
	},
	watch: {
		validate: function(error) {
			this.errors = new Errors(error.errors)
		}
	}
}
</script>