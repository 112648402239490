<template>
    <ts-panel>
        <ts-panel-wrapper>
            <h1 class="tw-mb-0">{{ $t('user.account') }}</h1>
            <small>{{ $t('user.createANewUserToOperateInSystem') }}</small>
            <div class="mb-3 tw-mt-4">
                <label class="mb-2 required">{{ $t('user.userName') }}</label>
                <input
                    v-model.trim="model.user_name"
                    type="text"
                    class="form-control fs-13px"
                    :placeholder="$t('user.userName')"
                    :class="{ 'is-invalid': errors.has('user_name') }"
                    :disabled="isEdit"
                />
                <div class="invalid-feedback" v-if="errors.has('user_name')">
                    {{ errors.first('user_name') }}
                </div>
            </div>
            <div class="mb-3">
                <label class="mb-2">{{ $t('user.email') }}</label>
                <input
                    v-model.trim="model.email"
                    type="email"
                    class="form-control fs-13px"
                    :placeholder="$t('user.emailAddress')"
                    :class="{ 'is-invalid': errors.has('email') }"
                />
                <div class="invalid-feedback" v-if="errors.has('email')">
                    {{ errors.first('email') }}
                </div>
            </div>
            <div v-if="!isEdit">
                <label class="mb-2 required">{{ $t('user.password') }}</label>
                <div class="row gx-3">
                    <div class="col-md-6 mb-2 mb-md-0">
                        <a-input-group compact>
                            <input
                                class="form-control"
                                :type="input_type"
                                v-model="model.password"
                                style="width: calc(100% - 41px)"
                                :class="{
                                    'is-invalid': errors.has('password')
                                }"
                                :placeholder="$t('shop.password')"
                            />
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="showPassword"
                            >
                                <i
                                    v-if="input_type === 'text'"
                                    class="toggle-password fas fa-eye"
                                ></i>
                                <i
                                    v-else
                                    class="toggle-password fa fa-fw fa-eye-slash"
                                ></i
                            ></ts-button>
                        </a-input-group>
                    </div>
                    <div class="col-md-6 mb-2 mb-md-0">
                        <a-input-group compact>
                            <input
                                class="form-control"
                                :type="input_type_confirmation"
                                v-model="model.password_confirmation"
                                style="width: calc(100% - 41px)"
                                :class="{
                                    'is-invalid': errors.has(
                                        'password_confirmation'
                                    )
                                }"
                                :placeholder="$t('shop.confirm')"
                            />
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="showPasswordConfirmation"
                            >
                                <i
                                    v-if="input_type_confirmation === 'text'"
                                    class="toggle-password fas fa-eye"
                                ></i>
                                <i
                                    v-else
                                    class="toggle-password fa fa-fw fa-eye-slash"
                                ></i
                            ></ts-button>
                        </a-input-group>
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapState } from 'vuex'

export default {
    name: 'userAccount',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {},
            input_type: 'password',
            input_type_confirmation: 'password'
        }
    },
    computed: {
        ...mapState('auth/user', ['edit_data']),
        isEdit () {
            return !isEmpty(this.edit_data)
        }
    },
    created () {
        this.model = this.value
    },
    methods: {
        showPassword () {
            if (this.input_type === 'password') {
                this.input_type = 'text'
            } else {
                this.input_type = 'password'
            }
        },
        showPasswordConfirmation () {
            if (this.input_type_confirmation === 'password') {
                this.input_type_confirmation = 'text'
            } else {
                this.input_type_confirmation = 'password'
            }
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
